import { SpotHealth } from '@/types/iot-portal';
import { stripIndent } from 'common-tags';

export interface SpotHealthMeta {
  value: SpotHealth;
  color: string;
  label: string;
  description?: string;
}

export const SPOT_HEALTH_META: { readonly [k in SpotHealth]: Readonly<SpotHealthMeta> } = Object.freeze({
  [SpotHealth.INACTIVE]: {
    value: SpotHealth.INACTIVE,
    color: '#000000',
    label: 'unbenutzt',
    description: 'An diesem Ort ist zur Zeit kein Gerät installiert.',
  },
  [SpotHealth.UNKNOWN]: {
    value: SpotHealth.UNKNOWN,
    color: '#4a4d4e',
    label: 'unbekannt',
    description: 'Bisher wurden keine Daten von diesem Gerät empfangen.',
  },
  [SpotHealth.CRITICAL]: {
    value: SpotHealth.CRITICAL,
    color: '#fe004f',
    label: 'kritisch',
    description: stripIndent`
      Eine Wartung ist notwendig, da seit längerer Zeit keine Daten von
      diesem Gerät empfangen wurden.
    `,
  },
  [SpotHealth.DEGRADED]: {
    value: SpotHealth.DEGRADED,
    color: '#f5a623',
    label: 'eingeschränkt',
    description: stripIndent`
      Eine Wartung wird möglicherweise notwendig. Dies kann verschiedene Gründe
      haben, z.B. wenn seit kurzem keine Daten von diesem Gerät empfangen
      werden, der Ladezustand der Batterie niedrig ist oder die Zertifierung des
      Geräts abgelaufen ist.
    `,
  },
  [SpotHealth.OK]: { value: SpotHealth.OK, color: '#66cd05', label: 'störungsfrei' },
});
